/* eslint-disable @typescript-eslint/no-explicit-any */
import { delay } from '@searchnode/utils-fp'
import { CustomError } from './error'

/* eslint-disable @typescript-eslint/no-explicit-any */
type PipedFuncArg = any
type PipedFunc<U extends any> = (args: U) => U

const _pipeTwo = <F extends (arg: PipedFuncArg) => any>(f: F, g: F) => (arg: PipedFuncArg) => g(f(arg))
// TODO replace with utils-fp
export const pipe = <U extends any>(...funcs: ReadonlyArray<PipedFunc<U>>) => funcs.reduce(_pipeTwo)

export function debounceTime<Input extends any[]>(
    func: (...args: Input) => Promise<void>,
    timeMs: number
): (...args: Input) => Promise<void> {
    // tslint:disable-next-line: no-let
    let timeOut: any | undefined
    return async (...args) => {
        return new Promise((resolve) => {
            clearTimeout(timeOut)
            timeOut = setTimeout(() => {
                func(...args).then(() => resolve())
            }, timeMs)
        })
    }
}

// TODO replace with utils-fp
export function sortBy<T>(arr: T[], key: (item: T) => unknown = (v) => v, order: 'asc' | 'desc' = 'asc'): T[] {
    const sorted = [...arr].sort((a, b) => {
        const doCompare = (keyA: unknown, keyB: unknown): number => {
            if (typeof keyA === 'number' && typeof keyB === 'number') {
                return keyA < keyB ? -1 : keyA > keyB ? 1 : 0
            }

            if (keyA instanceof Array && keyB instanceof Array) {
                const res = keyA.map((v, i) => doCompare(v, keyB[i])).filter((v) => v !== 0)
                return res.length ? res[0] : 1
            }

            if (typeof keyA === 'object' && typeof keyB === 'object') {
                return doCompare(Object.values(keyA ?? {})[0], Object.values(keyB ?? {})[0])
            }

            const stringA = String(keyA).toLowerCase()
            const stringB = String(keyB).toLowerCase()

            return stringA < stringB ? -1 : stringA > stringB ? 1 : 0
        }

        return doCompare(key(a), key(b))
    })
    return order === 'desc' ? sorted.reverse() : sorted
}

export function parseObjectPath(s: string): string[] {
    const props = s.replace(/\[(\w+)\]/g, '.$1') // convert array indexes to properties
    const normalized = props.replace(/^\./, '') // strip a leading dot
    return normalized.split('.')
}

export function getByDataPath<R extends unknown>(o: Record<string, any>, s: string): R | undefined {
    const arr = parseObjectPath(s)

    for (let i = 0, n = arr.length; i < n; ++i) {
        const k = arr[i]
        if (k in o) {
            o = o[k]
        } else {
            return
        }
    }
    return o as R
}

// export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
export type BulkActionResponse<R> = { completedItems?: R[] | undefined; error?: CustomError | undefined }

export function bulkActionFactory<T extends unknown, R extends unknown>(
    action: (item: T) => Promise<R>
): (data: T[]) => Promise<BulkActionResponse<R>> {
    return (data) => {
        let index = 0
        const completedItems: R[] = []
        let failedItemsCount = 0
        let backButtonClicked = false

        // we need to stop promise if back or forward button is clicked
        const handlePopState = () => {
            backButtonClicked = true
        }

        return new Promise((resolve) => {
            window.addEventListener('popstate', handlePopState)

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            async function next(): Promise<any> {
                if (!backButtonClicked && index < data.length) {
                    await delay(50)
                    return action(data[index++])
                        .then((item) => {
                            completedItems.push(item)
                        })
                        .catch(() => {
                            failedItemsCount = failedItemsCount + 1
                        })
                        .finally(next)
                } else {
                    window.removeEventListener('popstate', handlePopState)

                    resolve({
                        completedItems,
                        error:
                            failedItemsCount >= 1
                                ? new CustomError({
                                      message: `Action could not be completed for ${failedItemsCount} out of ${data.length} items.`,
                                  })
                                : undefined,
                    })
                }
            }

            next().then(resolve)
        })
    }
}

export function scrollToElementCenter(element: Element | null) {
    if (!element) return
    const elementRect = element.getBoundingClientRect()
    const absoluteElementTop = elementRect.top + window.pageYOffset
    const middle = absoluteElementTop - window.innerHeight / 2
    window.scrollTo({ top: middle, left: 0, behavior: 'smooth' })
}
