import Keycloak from 'keycloak-js'
import { Configuration, GroupModelExtended } from '@searchnode/dashboard-client'

export const publicUrl = process.env.PUBLIC_URL

export const themes = ['default', 'light', 'dark'] as const
export type Theme = typeof themes[number]

export function getTheme(theme: Theme): Theme {
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    return theme === 'default' && isDarkMode ? 'dark' : theme
}

export const keycloak = new Keycloak({
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'client-dashboard-frontend',
    realm: process.env.REACT_APP_KEYCLOAK_REALM || 'dev',
    url: process.env.REACT_APP_KEYCLOAK_URL || '',
})

export interface State {
    group?: GroupModelExtended
    theme: Theme
    user: {
        preferred_username: string
        permissions: string[]
    }
}

export interface LocalStorageState {
    groupId?: string
    theme?: Theme
}

export const defaultState: State = {
    group: undefined,
    theme: 'default',
    user: {
        preferred_username: 'guest',
        permissions: [],
    },
}

export async function getApiConfig(): Promise<Configuration> {
    await keycloak.updateToken(30)
    return new Configuration({
        accessToken: keycloak.token,
        basePath: process.env.REACT_APP_API_BASE_PATH || 'http://127.0.0.1:3001',
    })
}

export const localStorageKey = 'state'

export const permissionsNamespace = 'client-dashboard'

export const emergencyContact = 'emergency@searchnode.com'
