import { ComponentType, Suspense } from 'react'
import { useGlobal } from 'reactn'
import { Switch, Route } from 'react-router'

import { NavigationComponent } from './navigation'
import { UserBarComponent } from './navigation/userbar'
import { GroupsNavigation } from './navigation/groups'
import { PageLoaderComponent, lazyLoader } from './parts/loading'
import { PageNotFound } from './pages/errors'
import { SnRuleTargetsConfigType } from '@searchnode/dashboard-client'
import { rootRuleRouteOfType } from './pages/ranking-rules/ruleset-helpers'

export function ContentComponent(): JSX.Element {
    const [group] = useGlobal('group')

    const rankingRulesRoute = rootRuleRouteOfType[SnRuleTargetsConfigType.QueryRules]
    const relatedSearchesRoute = rootRuleRouteOfType[SnRuleTargetsConfigType.RelatedSearches]
    const bannersRoute = rootRuleRouteOfType[SnRuleTargetsConfigType.Banners]
    return (
        <div>
            <NavigationComponent group={group} />
            <div className='page-container'>
                <div className='header navbar'>
                    <div className='header-container'>
                        <ul className='nav-left'>
                            <li>
                                <a
                                    id='sidebar-toggle'
                                    className='sidebar-toggle'
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        document.body.classList.toggle('is-collapsed')
                                    }}
                                >
                                    <i className='ti-menu' />
                                </a>
                            </li>
                            <li>
                                <GroupsNavigation />
                            </li>
                        </ul>
                        <UserBarComponent />
                    </div>
                </div>
                <main className='main-content bgc-grey-100'>
                    <div id='mainContent' key={group?.id ?? 'default'}>
                        <Suspense fallback={<PageLoaderComponent />}>
                            <Switch>
                                <Route path='/' component={lazyLoader(() => import('./pages/home'))} exact />
                                <Route
                                    path='/settings'
                                    component={lazyLoader(() => import('./pages/settings'))}
                                    exact
                                />
                                <Route path='/flags' component={lazyLoader(() => import('./pages/flags'))} exact />
                                <Route
                                    path='/refinements'
                                    component={lazyLoader(() => import('./pages/refinements/groups'))}
                                    exact
                                />
                                <Route
                                    path={'/refinements/:refenementGroupId'}
                                    component={lazyLoader(() => import('./pages/refinements/items'))}
                                    exact
                                />
                                <Route
                                    path={'/refinements/:refinementsGroupId/wizard'}
                                    component={lazyLoader(() => import('./pages/refinements/wizard'))}
                                    exact
                                />
                                <Route
                                    path='/synonyms/indexes'
                                    component={lazyLoader(() => import('./pages/synonyms/indexes'))}
                                    exact
                                />
                                <Route
                                    path='/synonyms/lists'
                                    component={lazyLoader(() => import('./pages/synonyms/lists'))}
                                    exact
                                />
                                <Route
                                    path='/synonyms/indexes/:id'
                                    component={lazyLoader(() => import('./pages/synonyms/index-items'))}
                                    exact
                                />
                                <Route
                                    path='/synonyms/lists/:id'
                                    component={lazyLoader(() => import('./pages/synonyms/list-items'))}
                                    exact
                                />
                                <Route
                                    path={`${rankingRulesRoute}`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/rulesetList`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.QueryRules)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${rankingRulesRoute}/create`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/createRuleset`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.QueryRules)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${rankingRulesRoute}/:id/edit`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/editRuleset`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.QueryRules)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${rankingRulesRoute}/:id/:status?`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/ruleset`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.QueryRules)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${rankingRulesRoute}/:id/rule/create`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/rule/createRule`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.QueryRules)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${rankingRulesRoute}/:id/rule/:ruleId/:ruleStatus`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/rule/editRule`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.QueryRules)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${relatedSearchesRoute}`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/rulesetList`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.RelatedSearches)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${relatedSearchesRoute}/create`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/createRuleset`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.RelatedSearches)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${relatedSearchesRoute}/:id/edit`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/editRuleset`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.RelatedSearches)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${relatedSearchesRoute}/:id/:status?`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/ruleset`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.RelatedSearches)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${relatedSearchesRoute}/:id/rule/create`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/rule/createRule`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.RelatedSearches)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${relatedSearchesRoute}/:id/rule/:ruleId/:ruleStatus`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/rule/editRule`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.RelatedSearches)
                                    ))}
                                    exact
                                />
                                <Route
                                    path='/admin/groups'
                                    component={lazyLoader(() => import('./pages/admin/groups'))}
                                    exact
                                />
                                <Route
                                    path={`${bannersRoute}`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/rulesetList`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.Banners)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${bannersRoute}/create`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/createRuleset`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.Banners)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${bannersRoute}/:id/edit`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/editRuleset`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.Banners)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${bannersRoute}/:id/:status?`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/ruleset`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.Banners)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${bannersRoute}/:id/rule/create`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/rule/createRule`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.Banners)
                                    ))}
                                    exact
                                />
                                <Route
                                    path={`${bannersRoute}/:id/rule/:ruleId/:ruleStatus`}
                                    component={lazyLoader(() => import(`./pages/ranking-rules/rule/editRule`).then(
                                        wrapRuleType(SnRuleTargetsConfigType.Banners)
                                    ))}
                                    exact
                                />
                                 {/* <Route
                                    path={`${bannersRoute}`}
                                    component={lazyLoader(() => import('./pages/banners/indexes'))}
                                    exact
                                /> */}
                                <Route component={PageNotFound} />
                            </Switch>
                        </Suspense>
                    </div>
                </main>
            </div>
        </div>
    )
}

const wrapRuleType = (type: SnRuleTargetsConfigType) => (component: { default: (type: SnRuleTargetsConfigType) => ComponentType<unknown> }) => {
    return { 'default': component.default(type) }
}
