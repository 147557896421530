import { GroupModelExtended, SnRuleTargetsConfigType } from '@searchnode/dashboard-client'
import { Link, useLocation } from 'react-router-dom'
import { rootRuleRouteOfType } from '../pages/ranking-rules/ruleset-helpers'

export function NavigationComponent(props: { group: GroupModelExtended | undefined }): JSX.Element {
    const location = useLocation()

    const navCallback = () => {
        if (window.outerWidth < 991) {
            setTimeout(() => {
                document.body.classList.remove('is-collapsed')
            }, 100)
        }
    }

    const configOfTypeMissing = (type: SnRuleTargetsConfigType) => {
        return props.group?.ruleTargetsConfigs.find(cfg => cfg.type === type) === undefined
    }

    const items: ReadonlyArray<NavigationItem> = !props.group ? [] : [
        {
            title: 'Home',
            href: '/',
            icon: <i className='c-blue-500 ti-home' />,
            isActive: () => location.pathname === '/',
            disableRendering: () => false
        },
        {
            title: 'Refinements',
            href: '/refinements',
            icon: <i className='c-brown-500 ti-light-bulb' />,
            isActive: () => location.pathname.startsWith('/refinements'),
            disableRendering: () => false
        },
        {
            title: 'Synonyms',
            href: '/synonyms/indexes',
            icon: <i className='c-deep-orange-500 ti-link' />,
            isActive: () => location.pathname.startsWith('/synonyms'),
            disableRendering: () => false
        },
        {
            title: 'Related Searches',
            href: rootRuleRouteOfType[SnRuleTargetsConfigType.RelatedSearches],
            icon: <i className='c-deep-purple-500 ti-direction-alt' />,
            isActive: () => location.pathname.startsWith(rootRuleRouteOfType[SnRuleTargetsConfigType.RelatedSearches]),
            disableRendering: () => configOfTypeMissing(SnRuleTargetsConfigType.RelatedSearches)
        },
        {
            title: 'Ranking rules',
            href: rootRuleRouteOfType[SnRuleTargetsConfigType.QueryRules],
            icon: <i className='c-red-500 ti-panel' />,
            isActive: () => location.pathname.startsWith(rootRuleRouteOfType[SnRuleTargetsConfigType.QueryRules]),
            disableRendering: () => configOfTypeMissing(SnRuleTargetsConfigType.QueryRules)
        },
        {
            title: 'Banners',
            href: rootRuleRouteOfType[SnRuleTargetsConfigType.Banners],
            icon: <i className='c-red-500 ti-layout-cta-left' />,
            isActive: () =>  location.pathname.startsWith(rootRuleRouteOfType[SnRuleTargetsConfigType.Banners]),
            disableRendering: () => configOfTypeMissing(SnRuleTargetsConfigType.Banners)
        },
        {
            title: 'Settings',
            href: '/settings',
            icon: <i className='c-teal-500 ti-settings' />,
            isActive: () => location.pathname.startsWith('/settings'),
            disableRendering: () => false
        },
    ] as const

    return (
        <div className='sidebar'>
            <div className='sidebar-inner'>
                <div className='sidebar-logo'>
                    <div className='peers ai-c fxw-nw'>
                        <div className='peer peer-greed'>
                            <Link className='sidebar-link td-n' to='/' onClick={navCallback}>
                                <div className='peers ai-c fxw-nw'>
                                    <div className='peer'>
                                        <div className='logo'>
                                            <img src='/logo.png' alt='SearchNode logo' style={{ margin: '16.5px' }} />
                                        </div>
                                    </div>
                                    <div className='peer peer-greed'>
                                        <h5 className='lh-1 mB-0 logo-text'>SearchNode</h5>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='peer'>
                            <div className='mobile-toggle sidebar-toggle'>
                                <a
                                    className='td-n'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        document.body.classList.toggle('is-collapsed')
                                    }}
                                >
                                    <i className='ti-arrow-circle-left' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className='sidebar-menu scrollable pos-r ps'>
                    {items.map((item) => (
                        <NavigationItemComponent key={item.href} {...item} onClick={navCallback} />
                    ))}
                </ul>
            </div>
            {/* <GroupsNavigation /> */}
        </div>
    )
}

interface NavigationItem {
    readonly title: string
    readonly href: string
    readonly isActive: () => boolean
    readonly disableRendering: () => boolean
    readonly icon?: JSX.Element
    readonly children?: ReadonlyArray<NavigationItem>
}

const NavigationItemComponent = ({
    onClick,
    ...item
}: NavigationItem & { readonly onClick?: (item: NavigationItem) => void }) => item.disableRendering() ? <></> : (
    <li key={item.title} className={`nav-item ${item.isActive() ? 'active' : ''}`}>
        <Link className='sidebar-link' to={item.href} onClick={() => onClick?.(item)}>
            <span className='icon-holder'>{item.icon}</span>
            <span className='title'>{item.title}</span>
        </Link>

        {!!item.children?.length && (
            <ul>
                {item.children.map((child) => (
                    <NavigationItemComponent key={child.href} {...child} onClick={(v) => onClick?.(v)} />
                ))}
            </ul>
        )}
    </li>
)
