import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { setGlobal, addCallback } from 'reactn'

import { ContentComponent } from './components/content'
import { register } from './serviceWorker'
import {
    Theme,
    getTheme,
    publicUrl,
    keycloak,
    defaultState,
    State,
    localStorageKey,
    getApiConfig,
    LocalStorageState,
} from './config'
import { GroupsApi } from '@searchnode/dashboard-client'

import 'offline-js/offline'
import './styles'

export async function initApp(element: Element | null): Promise<void> {
    await keycloak.init(
        process.env.REACT_APP_SSO_ENABLED === 'true'
            ? {
                  onLoad: 'check-sso',
                  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
              }
            : {
                  onLoad: 'login-required',
                  checkLoginIframe: false,
              }
    )

    const loadedState = await loadState()

    await setGlobal(loadedState)
    updateTheme(loadedState.theme)

    addCallback((state) => {
        saveState(state)
        updateTheme(state.theme)
    })

    registerWebworker()

    ReactDOM.render(
        <BrowserRouter basename={publicUrl}>
            <ContentComponent key='content' />
        </BrowserRouter>,
        element
    )

    document.querySelector('#loader')?.classList.add('fadeOut')
}

async function loadState(): Promise<State> {
    const updatedDefaultState = {
        ...defaultState,
        user: await keycloak
            .loadUserInfo()
            .then((v) => v as State['user'])
            .catch(() => defaultState.user),
    }

    try {
        const storage = localStorage.getItem(localStorageKey)
        const data = storage ? (JSON.parse(storage) as LocalStorageState) : {}
        const theme = data.theme || updatedDefaultState.theme

        updateTheme(theme)

        return storage
            ? {
                  ...updatedDefaultState,
                  theme,
                  group: data.groupId
                      ? await new GroupsApi(await getApiConfig())
                            .getGroup(data.groupId)
                            .then((response) => response.data)
                            .catch(() => undefined)
                      : undefined,
              }
            : updatedDefaultState
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to load state')
        return updatedDefaultState
    }
}

function saveState(state: State): void {
    try {
        localStorage.setItem(
            localStorageKey,
            JSON.stringify({
                groupId: state.group?.id,
                theme: state.theme,
            } as LocalStorageState)
        )
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to save state')
    }
}

function updateTheme(theme: Theme): void {
    if (getTheme(theme) === 'dark') {
        document.documentElement.classList.remove('light-theme')
        document.documentElement.classList.add('dark-theme')
    } else {
        document.documentElement.classList.remove('dark-theme')
        document.documentElement.classList.add('light-theme')
    }
}

function registerWebworker(): void {
    register({
        onUpdate: () => {
            const div = document.createElement('div')
            div.style.position = 'fixed'
            div.style.bottom = '0'
            div.style.left = '0'
            div.style.right = '0'
            div.style.zIndex = '9999'
            div.style.textAlign = 'center'
            div.style.background = 'rgba(208, 208, 208, 0.90)'
            div.style.padding = '2px'
            div.className = 'text-secondary'
            div.innerHTML = `
                New content is available and will be used when all tabs for this page are closed
                <button class="btn btn-link" onClick="this.parentNode.style.display = 'none'">
                    x
                </button>`
            document.body.appendChild(div)
        },
    })
}
