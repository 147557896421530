import React from 'react'
import { useGlobal } from 'reactn'
import { Dropdown } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { style, media } from 'typestyle'

import { keycloak } from '../../config'

const styles = {
    userName: style(
        media(
            {
                maxWidth: 500,
            },
            {
                maxWidth: '47px',
            }
        ),
        {
            maxWidth: '100px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            lineHeight: 1.2,
        }
    ),
}

export function UserBarComponent(): JSX.Element {
    const [user] = useGlobal('user')

    const location = useLocation()

    // Hack to forse rerender component when location changes
    const accountUrl = location.pathname ? keycloak.createAccountUrl() : keycloak.createAccountUrl()

    return (
        <ul className='nav-right'>
            <Dropdown as={'li'}>
                <Dropdown.Toggle
                    as='a'
                    className='no-after peers fxw-nw ai-c lh-1'
                    id='dropdown-user'
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <div className='peer mR-10'>
                        <img className='w-2r bdrs-50p' src='/profile.png' alt='' />
                    </div>
                    <div className='peer'>
                        <span className='fsz-sm c-grey-900'>
                            <span className={styles.userName}>{user.preferred_username}</span>
                        </span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className='fsz-sm'>
                    <Dropdown.Item href={accountUrl} className='d-b td-n pY-5 c-grey-700'>
                        <i className='ti-settings mR-10'></i> <span>Account</span>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href={keycloak.createLogoutUrl()} className='d-b td-n pY-5 c-grey-700'>
                        <i className='ti-power-off mR-10'></i> <span>Logout</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ul>
    )
}
