import React, { ComponentType, LazyExoticComponent, useState, useEffect } from 'react'

export function PageLoaderComponent(): JSX.Element {
    const [show, setShow] = useState(false)
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true)
        }, 2000)
        return () => {
            clearTimeout(timeout)
        }
    }, [])
    return show ? (
        <div
            className='text-center'
            style={{
                fontSize: '30px',
                fontWeight: 'lighter',
                margin: '40vh auto',
                textAlign: 'center',
            }}
        >
            <span className='text-muted'>Loading...</span>
        </div>
    ) : (
        <></>
    )
}

export function lazyLoader<T extends ComponentType>(factory: () => Promise<{ default: T }>): LazyExoticComponent<T> {
    const wrapper = async (): Promise<{ default: T }> => {
        try {
            const component = await factory()
            return component
        } catch (e) {
            return new Promise((resolve) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const offline = (window as any).Offline
                const callback = () => {
                    resolve(wrapper())
                    offline.off('up', callback)
                }
                offline.on('up', callback)
            })
        }
    }

    return React.lazy(() => wrapper())
}
