import React, { useMemo, useState, useEffect } from 'react'
import { useGlobal } from 'reactn'
import { useHistory } from 'react-router-dom'
import { Dropdown, FormControl, Spinner } from 'react-bootstrap'
import Fuse from 'fuse.js'
import { style, media } from 'typestyle'

import { getApiConfig } from '../../config'
import { GroupsApi } from '@searchnode/dashboard-client'
import { useAsyncEffect, useSearchParam } from '../../helpers/hooks'
import { sortBy } from '../../helpers'

const styles = {
    groupName: style(
        media(
            {
                maxWidth: 500,
            },
            {
                maxWidth: '100px',
            }
        ),
        {
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            lineHeight: 1.2,
        }
    ),
}

export function GroupsNavigation(): JSX.Element {
    const [group, setGroup] = useGlobal('group')
    const [searchInput, setSearchinput] = useState('')
    const history = useHistory()
    const urlGroupId = useSearchParam('group')

    const [groups, { loading }] = useAsyncEffect(async () =>
        new GroupsApi(await getApiConfig())
            .getGroups()
            .then(({ data }) => sortBy(data, ({ resource }) => resource.name))
    )

    useEffect(() => {
        const updateGroup = async () => {
            const foundGroup = groups?.find(({ id }) => id === urlGroupId)
            if (foundGroup && foundGroup?.id !== group?.id) {
                new GroupsApi(await getApiConfig())
                    .getGroup(foundGroup.id)
                    .then(({ data }) => setGroup(data))
            }
        }
        updateGroup()
    }, [urlGroupId, groups, setGroup, group])

    const fuse = useMemo(
        () =>
            new Fuse(groups ?? [], {
                keys: ['resource.name'],
            }),
        [groups]
    )

    const searchGroups = useMemo(() => (groups && fuse && searchInput ? fuse.search(searchInput) : groups), [
        searchInput,
        fuse,
        groups,
    ])

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle
                    as='a'
                    className='no-after peers fxw-nw ai-c lh-1 pl-0 pr-0'
                    id='dropdown-user'
                    style={{
                        cursor: 'pointer',
                        padding: '23px',
                    }}
                >
                    <div className='peer'>
                        <span className='fsz-sm c-grey-900 d-flex'>
                            <i className='ti-star c-blue-500 align-self-center'></i>
                            <span className={`${styles.groupName} align-self-center ml-1`}>
                                {group ? group.resource.name : <span className='text-primary'>select a group</span>}
                            </span>{' '}
                            <small className='ml-1 ti-angle-down c-grey-600 align-self-center'></small>
                        </span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className='fsz-sm'>
                    <Dropdown.Header className='px-2 py-1'>
                        <FormControl
                            type='search'
                            placeholder='search...'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchinput(event.target.value)
                            }}
                        />
                    </Dropdown.Header>
                    {loading && (
                        <div className='d-flex justify-content-center p-5'>
                            <Spinner animation='grow' size='sm' />
                        </div>
                    )}
                    {groups?.length === 0 && (
                        <span className='d-flex justify-content-center p-3 text-warning'>no active groups</span>
                    )}
                    <div
                        style={{
                            maxHeight: '250px',
                            overflowY: 'auto',
                        }}
                    >
                        {searchGroups?.map(({ id, resource }) => (
                            <Dropdown.Item
                                key={id}
                                className='d-b td-n pY-5 c-grey-700'
                                onClick={() => {
                                    history.push(`/?group=${id}`)
                                }}
                                active={group?.id === id}
                            >
                                <span>{resource.name}</span>
                            </Dropdown.Item>
                        ))}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}
