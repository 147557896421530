import { SnRuleTargetsConfigType } from '@searchnode/dashboard-client'
import {
    RuleSetsResourceModel,
    RuleSetsTargetModel,
    RuleSetsTargetModelQueries,
    TargetsModel,
} from '@searchnode/dashboard-client/_generated/ranking-rules/client'
import { errorMessages, FormReturn, useForm } from '../../../helpers/hooks'

export interface RulesetFormFields {
    readonly name: string | undefined
    readonly targets: ReadonlyArray<RuleSetsTargetModel | RulesetTargetWithAll>
    readonly targetsAll: boolean
}

interface RulesetTargetWithAll {
    readonly region: string
    readonly customer: string
    readonly engine: string
    readonly queries: ReadonlyArray<RuleSetsTargetModelQueries | typeof QUERIES_SELECTED_ALL>
}

export const QUERIES_SELECTED_ALL = { key: '_all_', name: 'All' } as const

export function isQueriesSelectedAll(v: RuleSetsTargetModelQueries): v is typeof QUERIES_SELECTED_ALL {
    return typeof v === 'object' && v.key === QUERIES_SELECTED_ALL.key && v.name === QUERIES_SELECTED_ALL.name
}

export function useRuleSetForm(initial: RulesetFormFields): FormReturn<RulesetFormFields> {
    return useForm<RulesetFormFields>({
        ...initial,
        name: {
            value: initial.name,
            isRequired: true,
        },
        targets: {
            value: initial.targets,
            validationFn: ({ region, customer, engine, queries }, index, fields) => {
                if (fields.targetsAll) return true
                const isEmpty = !(region && customer && engine && queries.length > 0)
                if (fields.targets.length === 1 && isEmpty) {
                    return new Error(errorMessages.isEmpty)
                }
                if (fields.targets.length > 1 && (region || customer || engine) && isEmpty) {
                    return new Error(errorMessages.isEmpty)
                }
                return true
            },
        },
    })
}

export function ruleSetToApiFormat(
    values: RulesetFormFields,
    type: SnRuleTargetsConfigType,
    availableTargets: TargetsModel[]
): Pick<RuleSetsResourceModel, 'title' | 'meta'> {
    const targets = values.targets.flatMap((target): RuleSetsTargetModel[] => {
        if (!target.region || !target.engine || !target.customer) return []
        const hasAllQueriesSet = target.queries.some(isQueriesSelectedAll)

        const allQueries =
            availableTargets
                .find((v) => target.customer === v.customer)
                ?.engines?.find((v) => target.engine === v.engine)?.queries ?? []
        return [
            {
                region: target.region,
                customer: target.customer,
                engine: target.engine,
                queries: hasAllQueriesSet ? allQueries : target.queries.filter((v) => !isQueriesSelectedAll(v)),
            },
        ]
    })

    return {
        title: values.name,
        meta: {
            type,
            ...values.targetsAll ? { targetsAll: true } : { targets },
        }
    }
}

export const rootRuleRouteOfType = {
    [SnRuleTargetsConfigType.QueryRules]: '/ranking-rules',
    [SnRuleTargetsConfigType.RelatedSearches]: '/related-searches',
    [SnRuleTargetsConfigType.Banners]: '/banners'
} as const
    
export const rulePageTitleOfType = {
    [SnRuleTargetsConfigType.QueryRules]: 'Ranking Rules',
    [SnRuleTargetsConfigType.RelatedSearches]: 'Related Searches',
    [SnRuleTargetsConfigType.Banners]: 'Banners'

} as const