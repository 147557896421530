import { AxiosError } from 'axios'

export const defaultTitle = 'Something went wrong'
export const defaultText = 'An unknown error occurred.'

export type ErrorData =
    | {
          title?: string
          text?: string
          data?: Record<string, string>
      }
    | undefined

export class CustomError extends Error {
    title?: string
    data?: Record<string, string>

    constructor({ title, message, data }: { title?: string; message: string; data?: Record<string, string> }) {
        super()
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CustomError)
        }

        this.name = 'CustomError'
        this.title = title
        this.message = message
        this.data = data
    }
}

export async function parseError(error: unknown): Promise<string> {
    const isAxiosError = (error: unknown): error is AxiosError => {
        return typeof error === 'object' && !!error && 'isAxiosError' in error
    }

    const str =
        error instanceof Response
            ? await error.text()
            : isAxiosError(error) && error?.response?.data
            ? (error?.response?.data as Record<string, unknown>)
            : error instanceof Error
            ? error.message
            : String(error)

    if (typeof str === 'object' && 'error' in str) {
        return String(str.error)
    }

    try {
        return JSON.parse(str as string).error || str || defaultText
    } catch (_) {
        return String(str)
    }
}
