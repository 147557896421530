import { Dispatch, ReactNode, RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { parseError } from './error'
import {
    EnhancedRelatedSearchesAnyItemModel,
    RelatedSearchesSortColumn,
} from '../components/pages/related-searches/typings'
import { EnhancedSynonymsAnyItemModel, SynonymsSortColumn } from '../components/pages/synonyms/typings'
import { EnhancedRefinementModel, RefinementsSortColumn } from '../components/pages/refinements/typings'
import { AnyArray, debounceTime, keys, mapRecord } from '@searchnode/utils-fp'
import { mapToRecord } from '@searchnode/utils-fp/_es6'
import { isEmptyPrimitive } from './utils'

export function useAsyncEffect<T>(
    action: () => Promise<T>,
    deps: unknown[] = []
): [
    T | undefined,
    {
        loading: boolean
        error: string | undefined
    }
] {
    const data = useAsyncState(action, deps)
    return [data[0], data[2]]
}

export function useAsyncState<T>(
    action: () => Promise<T>,
    deps: unknown[] = []
): [
    T | undefined,
    (s: T | undefined | ((s: T) => T)) => void,
    {
        loading: boolean
        error: string | undefined
    }
] {
    const [state, setState] = useState<{
        value: T | undefined
        loading: boolean
        error: string | undefined
    }>({
        value: undefined,
        loading: false,
        error: '',
    })

    useEffect(() => {
        const doStuff = async () => {
            return await action()
        }

        const timeout = setTimeout(() => {
            setState((v) => ({
                ...v,
                loading: true,
            }))
        }, 50)

        doStuff()
            .then((v) => {
                clearTimeout(timeout)
                setState({
                    loading: false,
                    error: undefined,
                    value: v,
                })
            })
            .catch((error) => {
                clearTimeout(timeout)
                parseError(error).then((err) => {
                    setState({
                        loading: false,
                        error: undefined,
                        value: undefined,
                    })
                })
            })
    }, deps)

    return [
        state.value,
        (value) => {
            if (typeof value === 'function') {
                setState((s) => ({
                    ...s,
                    value: (value as Function)(s.value),
                }))
            } else {
                setState((s) => ({
                    ...s,
                    value: value,
                }))
            }
        },
        {
            loading: state.loading,
            error: state.error,
        },
    ]
}

export function useSearchParam<T extends string>(key: string): T | undefined {
    const location = useLocation()
    const params = useMemo(() => new URLSearchParams(location.search), [location.search])

    return params.get(key) as T
}

export function useListSearchParam<T extends string>(key: string): T[] | undefined {
    const location = useLocation()
    const params = useMemo(() => new URLSearchParams(location.search), [location.search])

    return params.getAll(key) as T[]
}

type SortOrder = 'asc' | 'desc'

export function useItemsSort<
    T extends EnhancedRelatedSearchesAnyItemModel | EnhancedSynonymsAnyItemModel | EnhancedRefinementModel,
    S extends RelatedSearchesSortColumn | SynonymsSortColumn | RefinementsSortColumn
>({
    items,
    searchInput,
    sortFn,
}: {
    items: T[] | undefined
    searchInput: string
    sortFn: (sortColumn: S, sortOrder: SortOrder) => T[]
}) {
    const [sortColumn, setSortColumn] = useState<S | undefined>('lastModified' as S)
    const [sortOrder, setSortOrder] = useState<SortOrder | undefined>('asc')
    const [sortedItems, setSortedItems] = useState(items)

    useEffect(() => {
        if (sortOrder && sortColumn) {
            setSortedItems(sortFn(sortColumn, sortOrder))
            // we need to remap sorted list in case some item is edited, so edited item does not change position
        } else if (items?.length === sortedItems?.length) {
            setSortedItems((prev) => prev?.map((si) => items?.find((i) => i.model.id === si.model.id) ?? si))
            // filter in case item is deleted
        } else if (items && sortedItems && items.length < sortedItems.length) {
            setSortedItems((prev) => prev?.filter((si) => items?.find((i) => i.model.id === si.model.id)))
        } else if (!searchInput && items && sortedItems && items.length > sortedItems.length) {
            setSortedItems((prev) => {
                const existingItems = prev?.map((si) => items?.find((i) => i.model.id === si.model.id) ?? si) ?? []
                const newItems = items.filter((i) => !prev?.find((si) => i.model.id === si.model.id))
                return [...newItems, ...existingItems]
            })
        }
    }, [items, sortColumn, sortOrder])

    return {
        sortColumn,
        setSortColumn,
        sortOrder,
        setSortOrder,
        sortedItems,
        setSortedItems,
    }
}

// ==============================

export function useAutoAddRemoveItems<T>({
    items,
    minEmptyItems = 1,
    maxEmptyItems = 1,
    isItemEmpty,
    onItemAdd,
    onItemRemove,
}: {
    readonly items: ReadonlyArray<T>
    readonly minEmptyItems?: number
    readonly maxEmptyItems?: number
    readonly isItemEmpty: (value: T) => boolean
    readonly onItemAdd: () => void
    readonly onItemRemove: (index: number) => void
}): void {
    useEffect(() => {
        const emptyItems = items.reduce<number[]>((acc, item, index) => {
            return isItemEmpty(item) ? [...acc, index] : acc
        }, [])
        if (emptyItems.length < minEmptyItems) {
            onItemAdd()
        } else if (emptyItems.length >= maxEmptyItems + 1) {
            const lastEmptyIndex = emptyItems[emptyItems.length - 1]
            onItemRemove(lastEmptyIndex)
        }
    }, [items])
}

// ==============================

export type FieldsConfig<Fields extends Record<keyof Fields, unknown>> = {
    [K in keyof Fields]: Fields[K] | AdvancedFieldConfig<Fields, K>
}

export type AdvancedFieldConfig<Fields extends Record<keyof Fields, unknown>, K extends keyof Fields> = {
    readonly value: Fields[K] | undefined
    readonly isRequired?: boolean
    readonly validationFn?: Fields[K] extends AnyArray<unknown>
        ? (newValue: Fields[K][number], index: number, fields: Fields) => boolean | Error
        : (newValue: Fields[K], fields: Fields) => boolean | Error
}

export type FormFields<Fields extends Record<keyof Fields, unknown>> = {
    [K in keyof Fields]: {
        readonly value: Fields[K]
        readonly errorMsg: Fields[K] extends AnyArray<unknown> ? ReadonlyArray<undefined | string> : undefined | string
        readonly isInvalid: Fields[K] extends AnyArray<unknown>
            ? ReadonlyArray<undefined | boolean>
            : undefined | boolean
        readonly onChange: (value: Fields[K]) => void
    }
}

export type FormReturn<Fields extends Record<keyof Fields, unknown>> = {
    readonly fields: FormFields<Fields>
    readonly submit: () => Fields | undefined
    readonly validate: (fieldKey?: Extract<keyof Fields, string>) => boolean
    readonly values: () => Fields
}

export function useForm<Fields extends Record<keyof Fields, unknown>>(
    config: FieldsConfig<Fields>
): FormReturn<Fields> {
    const fieldKeys = keys(config)

    const advancedFields = mapToRecord(
        (key) => (isAdvancedFieldGuard(config[key]) ? config[key] : { value: config[key] }),
        fieldKeys
    ) as Record<keyof Fields, AdvancedFieldConfig<Fields, keyof Fields>>

    const [fieldState, setFieldState] = useState(
        mapToRecord((key) => {
            const initialValue = advancedFields[key].value
            return Array.isArray(initialValue)
                ? {
                      value: initialValue,
                      errorMsg: initialValue.map<string | undefined>(() => undefined),
                      isInvalid: initialValue.map<string | undefined>(() => undefined),
                  }
                : {
                      value: initialValue,
                      errorMsg: undefined,
                      isInvalid: undefined,
                  }
        }, fieldKeys)
    )

    const mappedFields = mapToRecord( 
        (key) => ({
            ...fieldState[key],
            onChange: (newValue: unknown) =>
                setFieldState((state) => ({
                    ...state,
                    [key]: { ...state[key], value: newValue },
                })),
        }),
        fieldKeys
    ) as FormFields<Fields>

    function validateField(key: Extract<keyof Fields, string>): boolean {
        const fields = mapToRecord((key) => fieldState[key].value, fieldKeys) as Fields
        const { isRequired, validationFn } = advancedFields[key]
        const value = fieldState[key].value

        if (Array.isArray(value)) {
            const validationResults = value.map((val, index) => {
                const errorMsg = validateFieldValue(
                    val,
                    isRequired,
                    validationFn ? () => validationFn(val, index as number & Fields, fields) : undefined
                )
                setFieldState((state) => ({
                    ...state,
                    [key]: {
                        ...state[key],
                        errorMsg: value.map((_, i) => (i === index ? errorMsg : state[key].errorMsg?.[i])),
                        isInvalid: value.map((_, i) => (i === index ? !!errorMsg : state[key].isInvalid?.[i])),
                    },
                }))

                return errorMsg
            })
            return value.length ? validationResults.every((v) => !v) : true
        } else {
            const errorMsg = validateFieldValue(
                value,
                isRequired,
                validationFn
                    ? () => validationFn(value as Fields[keyof Fields], fields as number & Fields, undefined as never)
                    : undefined
            )
            setFieldState((state) => ({ ...state, [key]: { ...state[key], errorMsg, isInvalid: !!errorMsg } }))
            return !errorMsg
        }
    }

    function validateAll(): boolean {
        return fieldKeys.reduce<boolean>((result, key) => {
            return validateField(key) ? result : false
        }, true)
    }

    return {
        fields: mappedFields,
        submit: () => (validateAll() ? (mapRecord(({ value }) => value, fieldState) as Fields) : undefined),
        validate: (key) => (key ? validateField(key) : validateAll()),
        values: () => mapRecord(({ value }) => value, fieldState) as Fields,
    }
}

function isAdvancedFieldGuard(v: unknown): v is { readonly value: unknown } {
    return typeof v === 'object' && v != null && 'value' in v
}

export const errorMessages = {
    isEmpty: 'Field is required',
    isInvalid: 'Field is invalid',
    nonZero: 'Value should not be a zero',
    nonInteger: 'Value should be an integer',
}

function validateFieldValue(
    value: unknown,
    isRequired?: boolean,
    validationFn?: () => boolean | Error
): undefined | string {
    const requiredValidation = isRequired
        ? typeof value === 'string' || typeof value === 'number'
            ? !isEmptyPrimitive(value)
            : typeof value !== 'undefined'
        : true
    if (!requiredValidation) {
        return errorMessages.isEmpty
    }
    const customFnValidation = validationFn ? validationFn() : true
    if (customFnValidation === false) {
        return errorMessages.isInvalid
    }
    if (customFnValidation instanceof Error) {
        return customFnValidation.message
    }
}

// ==============================

export default function useLocalStorage<T>(key: string, initialValue: T): [T, Dispatch<T>] {
    const [value, setValue] = useState(() => {
        const value = getStoredValue()
        return value ?? initialValue
    })

    function getStoredValue(): T | undefined {
        const value = window.localStorage.getItem(key)
        return value ? JSON.parse(value) : undefined
    }

    const setItem = (newValue: T) => {
        setValue(newValue)
        window.localStorage.setItem(key, JSON.stringify(newValue))
    }

    useEffect(() => {
        const newValue = getStoredValue()
        if (JSON.stringify(value) !== JSON.stringify(newValue)) {
            setValue(newValue || initialValue)
        }
    })

    const handleStorage = useCallback(
        (event: StorageEvent) => {
            if (event.key === key && event.newValue && event.newValue !== JSON.stringify(value)) {
                setValue(JSON.parse(event.newValue) || initialValue)
            }
        },
        [value]
    )

    useEffect(() => {
        window.addEventListener('storage', handleStorage)
        return () => window.removeEventListener('storage', handleStorage)
    }, [handleStorage])

    return [value, setItem]
}

// ==============================

export function useElementsPerCssGridRow(gridElem: RefObject<HTMLElement>): number | undefined {
    const windowSize = useWindowSize()
    return useMemo(() => {
        return gridElem.current instanceof HTMLElement ? getProductTilesPerRow(gridElem.current) : undefined
    }, [windowSize.width, gridElem.current])
}

function useWindowSize(): Record<'width' | 'height', number> {
    const getSize = () => ({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const [value, setValue] = useState<Record<'width' | 'height', number>>(getSize())
    const resizeListener = debounceTime(async () => setValue(getSize()), 10)

    useEffect(() => {
        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    })

    return value
}

// add fake children till they not fit in the same row
function getProductTilesPerRow(gridElem: HTMLElement): number {
    const maxLoops = 50
    // tslint:disable-next-line
    let loop = 0
    // check if element is visible
    if (gridElem.getClientRects().length === 0) {
        return 0
    }
    const fakeChildren: HTMLElement[] = []
    const isFirstAndLastInSameRow = () => {
        if (fakeChildren.length <= 1) {
            return true
        }
        const firstTop = fakeChildren[0].getBoundingClientRect().top
        const lastTop = fakeChildren[fakeChildren.length - 1].getBoundingClientRect().top
        return firstTop === lastTop
    }
    while (isFirstAndLastInSameRow() && loop < maxLoops) {
        const fakeChild = document.createElement('div')
        fakeChild.style.height = '1px'
        fakeChildren.push(fakeChild)
        gridElem.prepend(fakeChild)
        loop++
    }
    const itemsPerRow = fakeChildren.length - 1
    fakeChildren.forEach((el) => el.remove()) // cleanup HTML
    return itemsPerRow
}
