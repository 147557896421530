import { AnyGroup, AnyIndex, AnyList } from '../typings'
import { AnyArray, isDefined } from '@searchnode/utils-fp'

export const getIndexConnectedLists = <T extends AnyIndex, U extends AnyList>(item: T, lists: U[] | undefined) =>
    lists ? lists.filter((g) => g.resource.indexIds?.some((v) => v === item.id)).filter(isDefined) : []

export const getTotalConnectedGroupsItemsCount = <T extends AnyGroup>(
    connectedGroups: T[] | undefined,
    getChildrenItemsCount: (item: T) => number
) => (connectedGroups ? connectedGroups?.reduce((sum, v) => sum + getChildrenItemsCount(v), 0) : 0)

export function isOneOf<T extends string | number | boolean>(
    value: string | number | boolean | undefined,
    array: ReadonlyArray<T>
): value is T {
    return typeof value !== 'undefined' && array.includes(value as T)
}

export function isEmptyPrimitive<T extends string | number | boolean>(value: T | undefined): value is undefined {
    if (typeof value === 'undefined') return true
    if (typeof value === 'string' && value.trim().length === 0) return true
    if (typeof value === 'number' && isNaN(value)) return true
    return false
}

/**
 * Immutable array helpers to make writing components logic easier
 */

export function arraySet<T extends unknown>(array: AnyArray<T>, index: number, value: T): T[] {
    return array.map((v: T, i: number) => (i === index ? value : v))
}

export function arrayDelete<T extends unknown>(array: AnyArray<T>, index: number): T[] {
    return array.filter((v, i) => i !== index)
}

export function arrayInsert<T extends unknown>(arr: AnyArray<T>, index: number, newItem: T): T[] {
    return [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted items
        newItem,
        // part of the array after the specified index
        ...arr.slice(index),
    ]
}

export function arrayMove<T extends unknown>(array: AnyArray<T>, from: number, to: number): T[] {
    const arrayCopy = [...array]
    const element = arrayCopy[from]
    arrayCopy.splice(from, 1)
    arrayCopy.splice(to, 0, element)

    return arrayCopy
}
